import React from 'react'

class Top extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasScrolled: false
    }

  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }
  handleScroll = () => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 1000) {
      this.setState({
        hasScrolled: true
      })
    }
    else {
      this.setState({
        hasScrolled: false
      })
    }
  }
  scrollTop() {

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  render() {
    return (
      <a class={this.state.hasScrolled ? 'scroll-to-top' : ''} onClick={this.scrollTop}>Back to top</a>


    )
  }
}
export default Top