import React from "react"
import Scrollspy from "react-scrollspy"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Footer from "../components/footer"
import Recommendations from "../components/recommendations"
import Top from "../components/top"
import { Link } from "gatsby"
import Back from "../components/back"
import Preview from '../components/Preview'

const TrustpilotShopify = props => (
    <Layout>
        <Preview />

        <Hero
            title="Trustpilot reviews on Shopify"
            summary="Improving how eCommerce businesses on Shopify collect and showcase Trustpilot reviews. 👜 "
            cover="https://res.cloudinary.com/dcj89ranh/image/upload/v1668260736/Shopify_Mockup_hero.svg"
        />

        <div class="grid md:grid-cols-3 px-6 md:px-24">
            <div class="text-left md:w-7/12 hidden md:block">
                <div
                    data-sal="slide-right"
                    data-sal-duration="900"
                    data-sal-delay="2000"
                    data-sal-easing="ease"
                    class="sticky top-32 flex flex-col gap-1.5 ml-2">
                        
                    <p class="container font-semibold text-theme-text py-2 text-sm tracking-wider text-left">
                        OVERVIEW ⤵
                    </p>
                    <Scrollspy
                        items={[
                            "section-1",
                            "section-2",
                            "section-3",
                            "section-4",
                            "section-5",
                            "section-6",
                            "section-7",
                        ]}
                        class="container text-base ml-8 w-8/12 py-1.5 rounded-sm cursor-pointer mb-16"
                        currentClassName="text-main-text container -ml-4 my-1 px-4 w-auto py-1.5 rounded-sm border-orange-600 border-l-4 bg-secondary font-bold ">
                        <li class="">
                            <a href="#section-1">TL;DR</a>
                        </li>
                        <li class="">
                            <a href="#section-2">Deep dive</a>
                        </li>
                        <li class="">
                            <a href="#section-3">Discovery & Research</a>
                        </li>

                        <li class="">
                            <a href="#section-4">Insights</a>
                        </li>
                        <li class="">
                            <a href="#section-5">Visual design</a>
                        </li>
                        <li class="">
                            <a href="#section-6">UX copy</a>
                        </li>
                        <li class="">
                            <a href="#section-7">Measuring impact</a>
                        </li>
                        <li class="">
                            <a href="#section-8">Key Learnings</a>
                        </li>
                       
                    </Scrollspy>

                    

                    {/* <div class="bg-secondary bg-special-500 rounded-sm p-5 my-12">
                        <blockquote class="text-left w-auto"><p>Learn more about my skills and experience</p></blockquote>

                        <Link to="https://res.cloudinary.com/dcj89ranh/image/upload/v1641133338/TrustScore/Badara_Olawale_Resume.pdf" target="_blank" data-sal="slide-up"
                            data-sal-duration="700"
                            data-sal-delay="310"
                            data-sal-easing="ease" class="transform transition duration-500 ease-in-out bg-main-text py-3 px-5 mt-4 text-secondary shadow-lg rounded-full float-left font-semibold text-sm hover:shadow-2xl hover:opacity-90">View my Resume</Link>


                    </div> */}


                </div>



            </div>

            <div class="divide-y divide-divider md:col-span-2 grid grid-cols-1 gap-8 md:gap-12">
                <section id="section-1" class="current">
                    <p class="text-2xl font-semibold text-left pt-8 pb-4">TL;DR </p>

                    <div class="subpixel-antialiased md:text-lg font-base leading-7 md:leading-8 text-left break-words">

                        <div class="md:inline-grid grid-cols-2 gap-4">
                            <div>
                                <p class="pb-2">
                                    <b>💼 Background</b>

                                </p>

                                <p class="pb-4 text-base leading-7">
                                    The Trustpilot Shopify app was initially listed on the Shopify app store to acquire new business users.
                                    In the first couple of months, the app received several reviews. Most of the reviews were negative and mentioned topics like pricing, login errors and some other usability issues.

                                </p>

                            </div>

                            <span>
                                <p class="pb-2">
                                    <b>🌪 The challenge</b>

                                </p>

                                <p class="pb-4 text-base leading-7">
                                    Negative reviews were a pointer that we needed to iterate on the current app. Hence, the goal of the project was to analyse these reviews, run discovery sessions and tackle the issues negatively impacting the experience of users.
                                </p>

                            </span>

                        </div>




                        <p class="py-4 font-bold">
                            🏗 What I did in this project

                        </p>
                        <div class="md:inline-grid grid-cols-2 gap-4 pb-8">
                            <div class="rounded-sm bg-secondary bg-opacity-10 p-4">
                                <img
                                    src="https://res.cloudinary.com/dcj89ranh/image/upload/v1668892432/Workshop.svg"
                                    class="w-9"
                                    alt="img-alt" />

                                <p class="text-base md:text-l pt-2 font-base text-left leading-7">

                                    Led user story mapping workshops with Engineers and the Product manager.
                                </p>
                            </div>

                            <div class="rounded-sm bg-secondary p-4 ">
                                <img
                                    src="https://res.cloudinary.com/dcj89ranh/image/upload/v1668892429/Competitor.svg"
                                    class="w-9"
                                    alt="img-alt" />

                                <p class="text-base md:text-l pt-2 font-base text-left leading-7">

                                    Evaluated competitor platforms to benchmark features and user journey.
                                </p>
                            </div>
                            <div class="rounded-sm bg-secondary p-4 ">
                                <img
                                    src="https://res.cloudinary.com/dcj89ranh/image/upload/v1668892432/Research.svg"
                                    class="w-9"
                                    alt="img-alt" />
                                <p class="text-base md:text-l pt-2 font-base text-left leading-7">

                                    Led research across the project’s lifecycle to understand the workflow of existing business users, jobs to be done (JTBD) and test prototypes.
                                </p>
                            </div>
                            <div class="rounded-sm bg-secondary p-4 ">
                                <img
                                    src="https://res.cloudinary.com/dcj89ranh/image/upload/v1668892429/Features.svg"
                                    class="w-9"
                                    alt="img-alt" />

                                <p class="text-base md:text-l pt-2 font-base text-left leading-7">

                                    Prioritised improvements and features in collaboration with the Product manager and Tech lead.
                                </p>
                            </div>
                            <div class="rounded-sm bg-secondary p-4 ">
                                <img
                                    src="https://res.cloudinary.com/dcj89ranh/image/upload/v1668892429/Insights.svg"
                                    class="w-9"
                                    alt="img-alt" />

                                <p class="text-base md:text-l pt-2 font-base text-left leading-7">

                                    Analysed research findings and tagged insights into user pain points, needs, wants, and motivation to inform decisions.
                                </p>
                            </div>
                            <div class="rounded-sm bg-secondary p-4 ">
                                <img
                                    src="https://res.cloudinary.com/dcj89ranh/image/upload/v1668892431/Polished.svg"
                                    class="w-9"
                                    alt="img-alt" />

                                <p class="text-base md:text-l pt-2 font-base text-left leading-7">

                                    Leveraged Shopify's <a href="https://polaris.shopify.com/components" class="underline hover:text-orange-600" target="_blank">Polaris Design System</a> to deliver polished interfaces and collaboratied with developers to evaluate tech feasibility.
                                </p>
                            </div>

                            <div class="rounded-sm bg-secondary p-4">
                                <img
                                    src="https://res.cloudinary.com/dcj89ranh/image/upload/v1668892432/Writing.svg"
                                    class="w-9"
                                    alt="img-alt" />

                                <p class="text-base md:text-l pt-2 font-base text-left leading-7">
                                    Partnered with UX writers to refine the voice and tone of the interface copy.
                                </p>
                            </div>
                            <div class="rounded-sm bg-secondary p-4">
                                <img
                                    src="https://res.cloudinary.com/dcj89ranh/image/upload/v1668892429/Listing.svg"
                                    class="w-9"
                                    alt="img-alt" />

                                <p class="text-base md:text-l pt-2 font-base text-left leading-7">
                                    Collaborated with the Product marketing team to update the app store listing.
                                </p>
                            </div>
                            <div class="rounded-sm bg-secondary p-4">
                                <img
                                    src="https://res.cloudinary.com/dcj89ranh/image/upload/v1668892429/Feedback.svg"
                                    class="w-9"
                                    alt="img-alt" />

                                <p class="text-base md:text-l pt-2 font-base text-left leading-7">
                                    Proposed strategies to continuously collect feedback from users and improve the app rating.
                                </p>
                            </div>
                            <div class="rounded-sm bg-secondary p-4">
                                <img
                                    src="https://res.cloudinary.com/dcj89ranh/image/upload/v1668892429/Illustrations.svg"
                                    class="w-9"
                                    alt="img-alt" />

                                <p class="text-base md:text-l pt-2 font-base text-left leading-7">
                                    Created product illustrations and solicited feedback from other designers.
                                </p>
                            </div>
                        </div>


                        <p class="subpixel-antialiased text-2xl md:text-3xl md:text-center font-semibold text-orange-600 leading-normal md:leading-tight">Full case study will be updated soon</p>
                        <p class="subpixel-antialiased text-base md:text-base md:text-center font-normal md:mx-auto md:w-9/12 py-4">Please check back later. I am working to update this case study and take you through the redesign process.</p>









                        {/* <p class="pb-4">

                            <b>🏷 The challenge</b> – From research (over 20+ customer interviews), the team discovered that all Trustpilot business users reference their TrustScore (this is the double digit value that appears on their Trustpilot profile) and businesses found this to be the most important metric they reference on Trustpilot. Also, businesses don't exactly understand what components of their workflow on Trustpilot contributes to their TrustScore.
                            One popular misconception is that only reviews distribution makes up the TrustScore, so we set out to fix that.
                        </p>


                        <div class="md:flex">
                            <div>
                                <img
                                    src="https://res.cloudinary.com/dcj89ranh/image/upload/v1641113684/TrustScore/B2B_dashboard.png"
                                    class="mx-auto bg-secondary px-4 pt-4 rounded-t-md"
                                    alt="img-alt" />
                            </div>



                        </div>
                        <p class="subpixel-antialiased text-sm font-base leading-7 py-4 text-center text-gray-300">
                            Some questions business users have about their TrustScore
                        </p>


                        <p class="py-6">
                            🥇 <b>The solution</b> – Telling a story with the TrustScore as the main character and components that make up the score in supporting roles.
                            The major question to answer is - <span class="font-medium">“How might we help users better understand their TrustScore and give them an overview of its health?”</span>

                        </p>
                         */}
                    </div>

                </section>

                <section id="section-2" class="current">
                    <p class="text-2xl font-semibold text-left pb-4 pt-8">
                        Deep dive
                    </p>


                    {/* <div class="md:flex flex-col gap-8 md:w-10/12">
                        <div class="rounded-sm bg-secondary p-4">
                            <p class="text-sm md:text-md tracking-wider font-semibold pb-2 text-left">
                                ROLE IN PROJECT
                            </p>
                            <p class="text-base md:text-l font-base text-left leading-7">
                                Product Designer • Ideation, Rapid Prototyping and Visual Design
                            </p>
                        </div>

                        <div class="rounded-sm bg-secondary p-4">
                            <p class="text-sm md:text-md tracking-wider font-semibold text-left pb-2 pt-8 md:pt-0">
                                USER GOAL(s)
                            </p>

                            <p class="text-base md:text-l font-base text-left leading-7">
                                •  See key insights into the TrustScore and its overall health.
                            </p>

                            <p class="text-base md:text-l font-base text-left leading-7">
                                •  Manage TrustScore and ultimately gain insights to improve customer experience.
                            </p>

                            <p class="text-sm md:text-md tracking-wider mt-4 font-semibold text-left pb-2 pt-8 md:pt-0">
                                BUSINESS GOAL(s)
                            </p>

                            <p class="text-base md:text-l font-base text-left leading-7">
                                •  Drive more engagement within the Trustpilot business app.
                            </p>


                        </div>
                    </div>


                    <p class="text-xl font-semibold text-left pt-8 pb-4">Hypothesis</p>
                    <p class="subpixel-antialiased text-base md:text-l font-base leading-7 pb-4 text-left">
                        A business' TrustScore (Trustpilot rating) is not only based on the positive or negative ratings from their customers but there is a misconception around concept.

                    </p>

                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        🧪 We believe that <i>if we effectively communicate how the TrustScore works, businesses would see more value in their TrustScore and this would lead to more business users engaging with customer reviews and leveraging on the reviews to improve the experience of customers.</i>
                        <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words"> Reflecting the lack of value communication is that over 80% of businesses say more transparency about what components impacts the TrustScore would help their business improvement efforts.</p>

                    </p>

                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        Also, based on research, businesses say they would like to reply to bad reviews (1 - 2 star reviews) to engage with customers who left these reviews and try to fix the problem that led to the poor experience. These reviews not only impact their TrustScore but also serves as valuable insights for what they can improve in their business.
                    </p>

                    <p class="text-xl font-semibold text-left pt-8 pb-4">Risks</p>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        The TrustScore is not the most straight forward metric to calculate so we have to be sure to explain what impacts this score clearly.
                        How do we mitigate the risk of confusing users?
                        We test the prototype with users in different segments (from those with less than 100 reviews to those with 1000+ reviews) and base design changes on the feedback.
                    </p>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        We leverage on input from our UX writers to ensure we put the right copy across to users and communicate with as much clarity as possible.
                    </p>



                    <div class="md:flex gap-8">

                        <div class="rounded-sm bg-secondary p-4 ">
                            <svg width="48" height="48" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle opacity="0.07" cx="30" cy="30" r="30" fill="#E01E5A" />
                                <path d="M18.4688 20.421V20.3872C18.4869 19.3261 19.3557 18.4688 20.4207 18.4688H39.5787C40.6553 18.4688 41.531 19.3447 41.531 20.421V39.579C41.531 40.6556 40.655 41.5312 39.5787 41.5312H20.421C19.3444 41.5312 18.4688 40.6553 18.4688 39.579V20.421ZM20.421 43.1H39.579C41.5207 43.1 43.1 41.5207 43.1 39.579V20.421C43.1 18.4796 41.5207 16.9 39.579 16.9H20.421C18.4796 16.9 16.9 18.4796 16.9 20.421V39.579C16.9 41.5207 18.4796 43.1 20.421 43.1Z" fill="#E3356B" stroke="#E3356B" stroke-width="0.2" />
                                <path d="M24.3823 35.6176L24.3824 35.6177C24.5356 35.7706 24.7364 35.8471 24.9369 35.8471C25.1374 35.8471 25.3382 35.7706 25.4915 35.6177L25.4915 35.6176L30 31.1089L34.5088 35.6174L34.5088 35.6174C34.6621 35.7703 34.8626 35.8468 35.0634 35.8468C35.2635 35.8468 35.4646 35.7704 35.6179 35.6174L35.618 35.6173C35.9242 35.3108 35.9242 34.8145 35.618 34.5082L35.5472 34.5789L35.618 34.5082L31.1092 29.9997L35.6174 25.4912L35.6174 25.4912C35.9236 25.1846 35.9236 24.6886 35.6174 24.3821L35.6173 24.382C35.3108 24.0758 34.8148 24.0758 34.5082 24.382L34.5082 24.382L30 28.8905L25.4912 24.3823L25.4912 24.3823C25.1847 24.0761 24.6886 24.0761 24.3821 24.3823L24.382 24.3824C24.0758 24.6889 24.0757 25.1852 24.382 25.4915L24.382 25.4915L28.8908 29.9997L24.3823 34.5087C24.3823 34.5087 24.3823 34.5087 24.3823 34.5087C24.076 34.815 24.0761 35.3111 24.3823 35.6176Z" fill="#E3356B" stroke="#E3356B" stroke-width="0.2" />
                            </svg>

                            <p class="text-base md:text-l pt-2 font-base text-left leading-7">

                                Further confusing users about the concept of their TrustScore
                            </p>
                        </div>
                    </div> */}

                </section>



                <section id="section-3" class="current">
                    <p class="text-2xl font-semibold text-left pb-4 pt-8">Discovery & Research</p>

                    {/* <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        Now that I have highlighted the challenge from a product point of view. Let’s talk about opportunities or challange from a design perspective.
                    </p>
                    <p class="subpixel-antialiased text-base md:text-l font-base leading-7 text-left pb-4 break-words">
                        The Business web app is based on components from the design system and there is an already established pattern in the Analytics section of the web app where the main content is displayed centrally on the page so the ideal approach is to leverage on this established pattern.
                    </p>

                    <div class="md:flex rounded-md">




                        <video controls
                            class="md:px-8 bg-secondary px-4 pt-4 rounded-t-md"
                            src="https://res.cloudinary.com/dcj89ranh/video/upload/v1640971842/TrustScore/Analytics_pattern.mov"
                            width="auto">

                            Sorry, your browser doesn't support embedded videos,
                            but don't worry, you can <a href="https://res.cloudinary.com/dcj89ranh/video/upload/v1640971842/Screen_Recording_2021-12-31_at_00.26.06.mov">download it</a>
                            and watch it with your favorite video player!

                        </video>


                    </div>
                    <p class="subpixel-antialiased text-sm font-base leading-7 py-4 text-center text-gray-300">
                        Established UI pattern of centering content on the page
                    </p>


                    <p class="subpixel-antialiased py-2 text-base md:text-l leading-7 text-left break-words">
                        So here are a list of elements that set the direction for the TrustScore insights page:</p>

                    <ol class="list-decimal text-base text-left p-4">
                        <li class="font-normal">
                            The existing components in the design system
                        </li>
                        <li class="font-normal">
                            The established pattern on other analytics pages
                        </li>
                        <li class="font-normal md:w-7/12">
                            The major risk highlighted regarding confusing users about the concept of the TrustScore.
                        </li>
                    </ol>


                    <div class="md:flex">

                        <img
                            src="https://res.cloudinary.com/dcj89ranh/image/upload/v1641114282/TrustScore/Low-fi.png"
                            class="mx-auto bg-secondary md:px-16 md:pt-8 rounded-t-md"
                            alt="img-alt" />
                    </div>
                    <p class="subpixel-antialiased text-sm font-base leading-7 py-4 text-center text-gray-300">
                        Rough sketch of the layout of information on the page. Credit: Product Manager
                    </p>

                    <p class="text-xl font-semibold text-left pt-8 pb-4">The main actor</p>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        The TrustScore is the main concept we tried to communicate on this page so I explored a couple of ways to show this (Made over 5 iterations of this part of the page).
                    </p>

                    <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/v1641114301/TrustScore/First_iteration.png"
                        class="mx-auto bg-secondary rounded-t-md"
                        alt="img-alt" />

                    <p class="subpixel-antialiased text-sm font-base leading-7 pb-4 text-center text-gray-300">
                        One of the first iteration of the main component of the page  </p>

                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        I iterated on the page based on internal testing so we went from the UI above to this  </p>

                    <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/v1641114429/TrustScore/Iteration_2.png"
                        class="mx-auto bg-secondary rounded-t-md"
                        alt="img-alt" />

                    <p class="subpixel-antialiased text-sm font-base leading-7 pb-4 text-center text-gray-300">
                        Next iteration of the main component of the page  </p>

                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        and then after some more internal test sessions...
                    </p>
                    <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/v1641114429/TrustScore/Iteration_3.png"
                        class="mx-auto bg-secondary rounded-t-md px-4 pt-4"
                        alt="img-alt" />

                    <p class="subpixel-antialiased text-sm font-base leading-7 py-4 text-center text-gray-300">
                        Next iteration of the TrustScore page  </p>

                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        At this point, we were ready to test the prototype with actual users. [Spoiler alert!] After testing this version with users, I had to reorganise how the information is displayed (re-grouped similar cards and reduced the amount of accent colours as this was confusing for some participants).
                    </p> */}


                </section>

                <section id="section-4" class="current">


                    <p class="text-2xl font-semibold text-left pb-4 pt-8">Insights</p>
                    {/* <p class="subpixel-antialiased py-2 text-base md:text-md font-medium leading-7 text-left break-words">

                        Super useful insights from users + Rapid prototyping = Magic ✨ </p>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        Based on the insight from the first round of test, I iterated on the existing UI and got ready for another round of testing.
                        This next round was more tasking as I had to synchronise content on the UI with the actual business data. I overcame this challenge by collaborating with the Product manager to get the actual data on a Google sheet doc and then used a Figma plugin to sync the data with the UI.
                    </p>

                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        In this next round of test, having the actual business data had a huge impact on how the businesses interacted with the prototype. They could relate more with the data presented, asked real questions and also gave some great insights!

                    </p>

                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        Here are some of the insights from this round of testing:
                    </p>
                    <div class="md:flex flex-col gap-4">
                        <div>
                            <img
                                src="https://res.cloudinary.com/dcj89ranh/image/upload/v1641114579/TrustScore/Insight_1.png"
                                class="mx-auto"
                                alt="img-alt" />
                        </div>
                        <div>
                            <img
                                src="https://res.cloudinary.com/dcj89ranh/image/upload/v1641114579/TrustScore/Insight_2.png"
                                class="mx-auto"
                                alt="img-alt" />
                        </div>

                        <div>
                            <img
                                src="https://res.cloudinary.com/dcj89ranh/image/upload/v1641114579/TrustScore/Insight_3.png"
                                class="mx-auto rounded-lg"
                                alt="img-alt" />
                        </div>


                    </div>

                    <p class="subpixel-antialiased text-sm font-base leading-7 py-4 text-center text-gray-300">
                        Some feedback from participants Credit: UX Researcher
                    </p>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        Here's a quick overview of the usability test sessions with Trustpilot business users
                    </p>
                    <div class="md:flex gap-8">
                        <div class="flex-1 rounded-sm bg-secondary bg-opacity-10 p-4">

                            <p class="text-3xl pt-2 font-bold leading-7">

                                5+
                            </p>
                            <p class="text-l pt-2 font-base text-center leading-7">

                                Prototypes/iterations
                            </p>
                        </div>

                        <div class="flex-1 rounded-sm bg-secondary bg-opacity-10 p-4">

                            <p class="text-3xl pt-2 font-bold leading-7">

                                3
                            </p>
                            <p class="text-l pt-2 font-base leading-7">

                                Usability test sessions
                            </p>
                        </div>

                        <div class="flex-1 rounded-sm bg-secondary p-4 md:w-6/12">
                            <p class="text-3xl pt-2 font-bold leading-7 md:w-8/12 md:mx-auto">

                                15
                            </p>
                            <p class="text-l pt-2 font-base leading-7">

                                Participants
                            </p>


                        </div>

                    </div>
                    <div class="md:flex flex-row gap-8 my-8">
                        <p class="flex-1 subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                            In all, the “TrustScore” card with “Days in current score”, the “Replies”, “Average rating (industry comparison)” and “Topics” sections were described as the most impactful.
                        </p>
                        <div class="relative md:flex-1 bg-secondary bg-special-500 rounded-sm p-6">
                            <blockquote class="mb-4 text-left"><p>“When is the team delivering this? Is this in production?...When are we going to have this? This is great!”</p></blockquote>
                            <div class="flex items-center space-x-4"><img src="https://images.unsplash.com/photo-1628890920690-9e29d0019b9b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80" alt="" class="flex-none w-12 h-12 rounded-full object-cover" />
                                <div class="flex-auto">
                                    <div class="text-base font-medium text-left">Participant 5</div>
                                </div></div></div>

                    </div>

                    <p class="flex-1 subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        So rolling in the insights from the last test session, I made some other changes to the page. Some of the updates included adding tooltips to some of the cards to provide more context for users.
                    </p>
                    <div class="flex gap-4 flex-col">
                        <img
                            src="https://res.cloudinary.com/dcj89ranh/image/upload/v1641114690/TrustScore/Final_UI.png"
                            class="mx-auto bg-secondary md:px-8 md:pt-8  rounded-t-md"
                            alt="img-alt" />

                        <img
                            src="https://res.cloudinary.com/dcj89ranh/image/upload/v1641114723/TrustScore/Final_UI_2.png"
                            class="mx-auto bg-secondary md:px-8 md:pt-8  rounded-t-md"
                            alt="img-alt" />
                        <p class="subpixel-antialiased text-sm font-base leading-7 text-center text-gray-300">
                            Next iteration of the TrustScore page  </p>

                    </div>
 */}



                </section>

                <section id="section-5" class="current">
                    <p class="text-2xl font-semibold text-left pb-4 pt-8">Visual design</p>
                    {/* <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        Another key group of collaborators in this project were the UX writers, they helped to ensure the copy clearly communicated concepts and also that we align the tone of our copy with the Trustpilot writing guidelines.
                    </p>

                    <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/v1641114784/TrustScore/Forecast_Tooltip.png"
                        class="mx-auto bg-secondary md:px-8 md:pt-8 rounded-t-md"
                        alt="img-alt" />

                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        One more thing to design for was translations. The TrustScore insights page would be available to business users from different locales so I had to design with translations in mind (which changed the sizing of some of the cards in the UI).
                    </p>

                    <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/v1641114807/TrustScore/TrustScore_Translations.png"
                        class="mx-auto bg-secondary md:px-8 md:pt-8 rounded-t-md"
                        alt="img-alt" />



                    <p class="subpixel-antialiased text-sm font-base leading-7 py-4 text-center text-gray-300">
                        The TrustScore card adapted to different locales</p> */}


                </section>


                <section id="section-6" class="current">
                    <p class="text-2xl font-semibold text-left pb-4 pt-8">UX copy</p>
                    {/* <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        Apart from qualitative feedback received from businesses, here are a some quantitative metrics on the new TrustScore page since its launch.
                        <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">From analytics we were able to validate the hypothesis that we will see an improvement in the reply rate to reviews (especially negative reviews).
                            Also, a Hotjar survey showed that over 54% of respondents thought the page was useful. This also opened up questions on how improvements can be made to the page and overall expected behaviour of businesses on the page. </p>
                    </p>
                    <div class="md:flex gap-8">
                        <div class="flex-1 rounded-sm bg-secondary bg-opacity-10 p-4">

                            <p class="text-3xl pt-2 font-bold leading-7">

                                96.9k
                            </p>
                            <p class="text-l pt-2 font-base text-center leading-7">

                                Total Page views
                            </p>
                        </div>

                        <div class="flex-1 rounded-sm bg-secondary bg-opacity-10 p-4">

                            <p class="text-3xl pt-2 font-bold leading-7">

                                30.1k
                            </p>
                            <p class="text-l pt-2 font-base leading-7">

                                Unique Page views
                            </p>
                        </div>

                        <div class="flex-1 rounded-sm bg-secondary p-4 md:w-6/12">
                            <p class="text-3xl pt-2 font-bold leading-7 md:w-8/12 md:mx-auto">

                                110+
                            </p>
                            <p class="text-l pt-2 font-base leading-7">

                                Businesses have loaded the page more than once
                            </p>


                        </div>

                    </div>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                            The new TrustScore page opened up a new opportunity to improve the experience of how businesses reply to and share reviews, these improvements were explored in another initiative by another team.
                        </p>

                    </p> */}


                </section>


                <section id="section-7" class="current">
                    <p class="text-2xl font-semibold text-left pt-8 pb-4">Measuring impact</p>
                    {/* <p class="subpixel-antialiased py-2 text-xl md:text-l font-bold leading-7 text-left break-words">
                        ☄️ Mooovee fast, learn, iterate!</p>


                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                            During this project, I was able to get great value from rapid prototyping and testing.
                            This helped the team discover incorrect assumptions we made in the designs/requirements quickly and also re-estimate the degree of impact the solution might have on our business users as well as learn more about their workflow.
                        </p>

                        <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                            Also, before the usability test session, I synchronized the data displayed in the prototype with the participants actual data. This allowed them to better understand what was displayed and follow the story we told on the page.               </p>
                        Another learning here is to try to leverage on real data during test sessions to provide users with a true picture of the concept being tested.
                    </p> */}


                </section>

                <section id="section-8" class="current">
                    <p class="text-2xl font-semibold text-left pb-4 pt-8">Key Learnings</p>
                    {/* <p class="subpixel-antialiased py-2 text-xl md:text-l font-bold leading-7 text-left break-words">
                        ☄️ Mooovee fast, learn, iterate!</p>


                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                            During this project, I was able to get great value from rapid prototyping and testing.
                            This helped the team discover incorrect assumptions we made in the designs/requirements quickly and also re-estimate the degree of impact the solution might have on our business users as well as learn more about their workflow.
                        </p>

                        <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                            Also, before the usability test session, I synchronized the data displayed in the prototype with the participants actual data. This allowed them to better understand what was displayed and follow the story we told on the page.               </p>
                        Another learning here is to try to leverage on real data during test sessions to provide users with a true picture of the concept being tested.
                    </p> */}


                </section>


            </div>
        </div >
        <Recommendations projectName="trustscore" />
        <Footer />
    </Layout >
)

export default TrustpilotShopify
